import axiosCommerceInstance from '../../setup/axios/axiosCommerceInstance'

const API_URL = 'https://devapi-commerce.emaq.ba/api'
const accessAllowOrigin = 'https://devapi-commerce.emaq.ba'

export const GET_USER_ADDRESSES = `${API_URL}/Addresses?AdditionalData.IncludeList=City&AdditionalData.IncludeList=Country`
export const GET_CITIES = `${API_URL}/Cities`
export const ADD_ADDRESS = `${API_URL}/Addresses`
export const REMOVE_ADDRESS = `${API_URL}/Addresses`
export const UPDATE_ADDRESS = `${API_URL}/Addresses`
export const GET_COUNTRIES = `${API_URL}/Countries`
export const GET_ADRRESS_ID = `${API_URL}/Addresses`
export const GET_USER_LEVEL = `https://devapi-commerce.emaq.ba/api/Levels/GetLevelByPoints`
export const GET_QUICKACTIONS = `https://devapi-commerce.emaq.ba/api/TenantQuickActions?tenantId=2`

export async function getUserLevel(points: number) {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,
      'Accept-Language': 'ba',
    },
  }

  return await axiosCommerceInstance.get(`${GET_USER_LEVEL}?points=${points}&tenantId=2`, config)
}

export async function getUserAddresses(data: any) {
  var user = JSON.parse(data.user)

  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,
      'Accept-Language': 'ba',
    },
  }
  return await axiosCommerceInstance.get(`${GET_USER_ADDRESSES}&userId=${user.userId}`, config)
}
export async function getAdressById(id: any) {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,

      'Accept-Language': 'ba',
    },
  }

  return await axiosCommerceInstance.get(`${GET_ADRRESS_ID}/${id}`, config)
}

export async function getCities() {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,

      'Accept-Language': 'ba',
    },
  }

  return await axiosCommerceInstance.get(GET_CITIES, config)
}

export async function getCountries() {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,

      'Accept-Language': 'ba',
    },
  }

  return await axiosCommerceInstance.get(GET_COUNTRIES, config)
}

export async function addAddress(address: any) {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,

      'Accept-Language': 'ba',
    },
  }
  return await axiosCommerceInstance.post(ADD_ADDRESS, address, config)
}

export async function removeAddress(id: any) {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,

      'Accept-Language': 'ba',
    },
  }

  return await axiosCommerceInstance.delete(`${ADD_ADDRESS}/${id}`, config)
}

export async function updateAddress(id: any, address: any) {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,

      'Accept-Language': 'ba',
    },
  }

  return await axiosCommerceInstance.patch(`${UPDATE_ADDRESS}/${id}`, address, config)
}

export async function getQuickActions() {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,

      'Accept-Language': 'ba',
    },
  }

  return await axiosCommerceInstance.get(GET_QUICKACTIONS, config)
}
