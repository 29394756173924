import {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Formik, useFormik} from 'formik'
import {updatePersonalInfo} from '../../../services/UserServices'
import {getAppTheme, getStorageAccessToken} from '../../../helpers/helpers'
import store from '../../../../setup/redux/Store'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {AppTheme} from '../../../models/AppThemeModel'

const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export function PersonalInformation(props: any) {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const personalInfoSchema = Yup.object().shape({
    ime: Yup.string()
      .min(
        3,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 3 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        20,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}20${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_NAMEREQUIRED'})}`),
    prezime: Yup.string()
      .min(
        3,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 3 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        20,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}20${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_LASTNAMEREQUIRED'})}`),
  })
  const [startDate, setStartDate] = useState(new Date())

  const [image, setImage] = useState(props.data.photoUrl)
  const [theme, setTheme] = useState<AppTheme>()
  const [formImage, setFormImage] = useState(props.data.photoUrl)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(props.data.phone)
  const dispatch = useDispatch()

  useEffect(() => {
    setImage(props.data.photoUrl)
    setFormImage(props.data.photoUrl)
    setTheme(getAppTheme())
  }, [])
  const initialValues = {
    ime: props.data.privateIndividual.firstName,
    prezime: props.data.privateIndividual.lastName,
    brojTelefona: state,
  }
  const [imageChanged, setImageChanged] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: personalInfoSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      var personalInfoData = {
        Email: props.data.emailStripped,
        Phone: state.toString(),
        File: imageChanged ? formImage : image,
        PrivateIndividual: {
          FirstName: values.ime,
          LastName: values.prezime,
          BirthDate: startDate,
        },
      }
      updatePersonalInfo(personalInfoData, getStorageAccessToken())
        .then((response) => {
          var usr = store.getState()

          handleCloseModal()
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    },
  })

  async function profileImageChange(event: any) {
    var img = URL.createObjectURL(event.target.files[0])
    setImage(img)
    setFormImage(event.target.files[0])
    var convertedImg = await convertToBase64(event.target.files[0])
    setFormImage(convertedImg)
    setImageChanged(true)
  }

  function changePhoneNumber(e: any) {
    formik.touched.brojTelefona = true
    if (e.length == 0) {
      formik.errors.brojTelefona = intl.formatMessage({id: 'VALIDATOR.FORMAT_NUMBEREQUIRED'})
    } else if (e.length > 0 && e.length < 11) {
      formik.errors.brojTelefona = intl.formatMessage({id: 'PHONENUMBER.SIZE_TEXT'})
    } else if (e.length >= 11 && e.length < 15) {
      formik.touched.brojTelefona = true
      formik.errors.brojTelefona = undefined
    }
    setState(e)
  }

  function handleCloseModal() {
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 3000)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              {' '}
              {intl.formatMessage({id: 'USERPROFILE.PERSONAL_TITLE'})}
            </h3>
          </div>
        </div>
        <div id='kt_account_settings_profile_details' className='collapse show'>
          <form
            id='kt_account_profile_details_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
            autoComplete='off'
            encType='multipart/form-data'
          >
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'USERPROFILE.PERSONAL_IMAGE'})}
                </label>
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{backgroundImage: `url(${image}`}}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{backgroundImage: `url(${image}`}}
                    ></div>
                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='change'
                      data-bs-toggle='tooltip'
                      title='Change avatar'
                    >
                      <i className='bi bi-pencil-fill fs-7'></i>
                      <input
                        type='file'
                        name='avatar'
                        accept='.png, .jpg, .jpeg'
                        onChange={profileImageChange}
                      />
                      <input type='hidden' name='avatar_remove' />
                    </label>
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='cancel'
                      data-bs-toggle='tooltip'
                      title='Cancel avatar'
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='remove'
                      data-bs-toggle='tooltip'
                      title='Remove avatar'
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                  </div>
                  <div className='form-text'>
                    {intl.formatMessage({id: 'USERPROFILE.PERSONAL_IMAGE_FORMAT'})}
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'USERPROFILE.PERSONAL_FULLNAME'})}
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        {...formik.getFieldProps('ime')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid  mb-3 mb-lg-0',
                          {'is-invalid': formik.touched.ime && formik.errors.ime},
                          {
                            'is-valid': formik.touched.ime && !formik.errors.ime,
                          }
                        )}
                        type='text'
                        name='ime'
                        autoComplete='off'
                        placeholder='Ime'
                      />
                      {formik.touched.ime && formik.errors.ime && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert'>{formik.errors.ime}</span>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <input
                        placeholder='Prezime'
                        {...formik.getFieldProps('prezime')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.prezime && formik.errors.prezime},
                          {
                            'is-valid': formik.touched.prezime && !formik.errors.prezime,
                          }
                        )}
                        type='text'
                        name='prezime'
                        autoComplete='off'
                      />
                      {formik.touched.prezime && formik.errors.prezime && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert'>{formik.errors.prezime}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'USERPROFILE.PERSONAL_NUMBERDATE'})}
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <PhoneInput
                        inputProps={{name: 'brojTelefona'}}
                        {...formik.getFieldProps('brojTelefona')}
                        buttonStyle={{border: 'none', borderRight: '1px solid lightgrey'}}
                        inputStyle={{width: '100%', fontFamily: 'Poppins', fontWeight: '500'}}
                        country={'ba'}
                        regions={['europe']}
                        inputClass={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.brojTelefona && formik.errors.brojTelefona},
                          {
                            'is-valid': formik.touched.brojTelefona && !formik.errors.brojTelefona,
                          }
                        )}
                        masks={{ba: '(..) ...-...-.'}}
                        onChange={changePhoneNumber}
                      />

                      {formik.touched.brojTelefona && formik.errors.brojTelefona && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert'>{formik.errors.brojTelefona}</span>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-6 fv-row mt-3 mt-md-0'>
                      <DatePicker
                        dateFormat='dd.MM.yyyy'
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                        className={'form-control form-control-lg form-control-solid'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='card-footer d-flex justify-content-end py-6 px-9'
              style={{border: 'none'}}
            >
              <button type='reset' className='btn btn-light btn-active-light-primary me-2'>
                {intl.formatMessage({id: 'GENERAL.CANCEL'})}
              </button>

              <button
                type='submit'
                className='btn'
                style={{backgroundColor: `${theme?.fontColor!}`, color: 'white'}}
                id='kt_account_profile_details_submit'
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'GENERAL.SAVECHANGES'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-label'>
                    Pričekajte..
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>

        <Modal show={show}>
          <Modal.Header>
            <Modal.Title> {intl.formatMessage({id: 'CHANGEDINFO.TITLE'})}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{intl.formatMessage({id: 'CHANGEDINFO.TEXT'})}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
