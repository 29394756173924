/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link, useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {UserModel} from '../auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {getUserInfo} from '../../services/UserServices'
import {getStorageAccessToken} from '../../helpers/helpers'
import {getUserPoints} from '../../services/OrdersServices'
import {getUserLevel} from '../../services/AddressesServices'

const ProfileHeader: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [hasData, setHasData] = useState(false)
  const [hasPoints, setHasPoints] = useState(false)
  const [totalPoints, setTotalPoints] = useState(0)
  const [totalOrders, setTotalOrders] = useState(0)
  const [userLevel, setUserLevel] = useState({name: '', color: ''})
  const [userInfo, setUserInfo] = useState({
    phone: '',
  })

  function calculatePoints(pointslogs: any) {
    var totalPrev = 0
    var totalOrdersCount = 0
    pointslogs.forEach((log: any) => {
      if (log.hasOwnProperty('orderId')) {
        totalOrdersCount += 1
      }
      totalPrev += log.points
      setTotalOrders(totalOrdersCount)
      setTotalPoints(totalPrev)
    })
  }

  useEffect(() => {
    var url = localStorage.getItem('redirectUrl')
    if (url != null) {
      localStorage.removeItem('redirectUrl')
    } else {
      localStorage.removeItem('redirectUrl')
    }

    getUserInfo(getStorageAccessToken())
      .then((response) => {
        if (response.data != null) setHasData(true)
        setUserInfo(response.data)
        return
      })
      .catch((err) => {
        console.log(err)
      })
    setTimeout(() => {
      var data = localStorage.getItem('persist:emaq-usersession')
      var userData = JSON.parse(data!)
      getUserPoints(userData)
        .then((response) => {
          calculatePoints(response.data.resultList)

          if (response.data.resultList.length > 0) {
            var totalPrev = 0
            var totalOrdersCount = 0
            response.data.resultList.forEach((log: any) => {
              if (log.hasOwnProperty('orderId')) {
                totalOrdersCount += 1
              }
              totalPrev += log.points
              setTotalOrders(totalOrdersCount)
              setTotalPoints(totalPrev)
            })
            getUserLevel(totalPrev).then((response) => {
              setUserLevel(response.data)
            })
            setHasPoints(true)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }, 200)

    return () => {
      setHasData(false)
    }
  }, [])

  const location = useLocation()
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              {hasData && <img src={user.photoUrl} alt='Metornic' />}
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex  row'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {user.username}
                  </a>
                  <a href='#'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {user.email}
                  </a>
                  {userInfo.phone && (
                    <a
                      style={{marginLeft: '10px'}}
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/electronics/elc002.svg'
                        className='svg-icon-4 me-1'
                      />
                      {userInfo.toString().includes('+') == true && userInfo.phone}
                      {userInfo.toString().includes('+') == false && '+' + userInfo.phone}
                    </a>
                  )}
                  <a
                    style={{marginLeft: '10px'}}
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    {/* <KTSVG
                      path='/media/icons/duotune/electronics/elc002.svg'
                      className='svg-icon-4 me-1'
                    /> */}
                  </a>
                </div>
              </div>
            </div>
            {hasPoints && (
              <div className='row col-10'>
                {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 col-2 '>
                  <div
                    className='fs-2 fw-bolder'
                    data-kt-countup='true'
                    data-kt-countup-value='4500'
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-3' />{' '}
                    {spentTotal.toFixed(1)}
                  </div>

                  <div className='fw-bold fs-7 text-gray-400'>
                    {intl.formatMessage({id: 'PROFILE.TOTAL_POINTS_SPENT'})}
                  </div>
                </div> */}

                {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 col-2 '>
                  <div
                    className='fs-2 fw-bolder'
                    data-kt-countup='true'
                    data-kt-countup-value='4500'
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr073.svg' className='svg-icon-3' />{' '}
                    {gainedTotal.toFixed(1)}
                  </div>
                  <div className='fw-bold fs-7 text-gray-400'>
                    {intl.formatMessage({id: 'PROFILE.TOTAL_POINTS_GAINED'})}
                  </div>
                </div> */}

                <div
                  className={
                    window.innerWidth < 600
                      ? 'border border-gray-300 border-dashed rounded min-w-115px py-3 px-4 me-6 mb-3 col-5'
                      : 'border border-gray-300 border-dashed rounded min-w-115px py-3 px-4 me-6 mb-3 col-3'
                  }
                >
                  {totalPoints > 0 && (
                    <div
                      className='fs-3 fw-bolder text-success'
                      data-kt-countup='true'
                      data-kt-countup-value='4500'
                    >
                      <KTSVG
                        path='/media/icons/duotune/finance/fin008.svg'
                        className='svg-icon-3'
                      />{' '}
                      {totalPoints.toFixed(1)}
                    </div>
                  )}
                  {totalPoints == 0 && (
                    <div
                      className='fs-3 fw-bolder '
                      data-kt-countup='true'
                      data-kt-countup-value='4500'
                    >
                      <KTSVG
                        path='/media/icons/duotune/finance/fin008.svg'
                        className='svg-icon-3'
                      />{' '}
                      {totalPoints.toFixed(1)}
                    </div>
                  )}
                  <div className='fw-bold fs-7 text-gray-400'>
                    {intl.formatMessage({id: 'PROFILE.TOTAL_POINTS'})}
                  </div>
                </div>

                <div
                  className={
                    window.innerWidth < 600
                      ? 'border border-gray-300 border-dashed rounded min-w-115px py-3 px-4 me-6 mb-3 col-5'
                      : 'border border-gray-300 border-dashed rounded min-w-115px py-3 px-4 me-6 mb-3 col-3'
                  }
                >
                  {totalOrders > 0 && (
                    <div
                      className='fs-2 fw-bolder text-primary'
                      data-kt-countup='true'
                      data-kt-countup-value='4500'
                    >
                      <KTSVG
                        path='/media/icons/duotune/ecommerce/ecm005.svg'
                        className='svg-icon-3'
                      />{' '}
                      {totalOrders}
                    </div>
                  )}
                  {totalOrders == 0 && (
                    <div
                      className='fs-2 fw-bolder '
                      data-kt-countup='true'
                      data-kt-countup-value='4500'
                    >
                      <KTSVG
                        path='/media/icons/duotune/ecommerce/ecm005.svg'
                        className='svg-icon-3'
                      />{' '}
                      {totalOrders}
                    </div>
                  )}
                  <div className='fw-bold fs-7 text-gray-400'>
                    {intl.formatMessage({id: 'PROFILE.TOTAL_ORDERS_COUNT'})}
                  </div>
                </div>
                <div
                  className={
                    window.innerWidth < 600
                      ? 'border border-gray-300 border-dashed rounded min-w-115px py-3 px-4 me-6 mb-3 col-5'
                      : 'border border-gray-300 border-dashed rounded min-w-115px py-3 px-4 me-6 mb-3 col-3'
                  }
                >
                  {userLevel && (
                    <div
                      className='fs-3 fw-bolder text'
                      data-kt-countup='true'
                      data-kt-countup-value='4500'
                      style={{color: userLevel.color!}}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen029.svg'
                        className='svg-icon-3'
                      />{' '}
                      {userLevel.name.toUpperCase()}
                    </div>
                  )}
                  {/* {userLevel == 'Gold' && (
                    <div
                      className='fs-3 fw-bolder text'
                      data-kt-countup='true'
                      data-kt-countup-value='4500'
                      style={{color: '#ffd700'}}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen029.svg'
                        className='svg-icon-3'
                      />{' '}
                      {userLevel}
                    </div>
                  )}
                  {userLevel == 'Diamond' && (
                    <div
                      className='fs-3 fw-bolder text'
                      data-kt-countup='true'
                      data-kt-countup-value='4500'
                      style={{color: '#b9f2ff'}}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen029.svg'
                        className='svg-icon-3'
                      />{' '}
                      {userLevel}
                    </div>
                  )}

                  {userLevel == 'Platinum' && (
                    <div
                      className='fs-3 fw-bolder text'
                      data-kt-countup='true'
                      data-kt-countup-value='4500'
                      style={{color: '#E5E4E2'}}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen029.svg'
                        className='svg-icon-3'
                      />{' '}
                      {userLevel}
                    </div>
                  )} */}

                  <div className='fw-bold fs-7 text-gray-400'>
                    {intl.formatMessage({id: 'PROFILE.USER_LEVEL'})}
                  </div>
                </div>
              </div>
            )}
            {/* <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>4500$</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Earnings</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr065.svg'
                        className='svg-icon-3 svg-icon-danger me-2'
                      />
                      <div className='fs-2 fw-bolder'>75</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>60%</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Success Rate</div>
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                  <span className='fw-bolder fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div>
            </div>
*/}
          </div>
        </div>
        {window.innerWidth < 992 && (
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/profile/overview' && 'active')
                  }
                  to='/profile/overview'
                >
                  {intl.formatMessage({id: 'PROFILE.HEADER_OVERVIEW'})}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/profile/projects' && 'active')
                  }
                  to='/profile/projects'
                >
                  {intl.formatMessage({id: 'PROFILE.HEADER_USER_PROFILE'})}
                </Link>
              </li>
              {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/crafted/pages/profile/campaigns' && 'active')
                }
                to='/crafted/pages/profile/campaigns'
              >
                Campaigns
              </Link>
              </li>
           */}
              {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/crafted/pages/profile/logs' && 'active')
                }
                to='/crafted/pages/profile/logs'
              >
                Sesije
              </Link>
            </li> */}
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/profile/orders' && 'active')
                  }
                  to='/profile/orders'
                >
                  {intl.formatMessage({id: 'PROFILE.HEADER_ORDERS'})}
                </Link>
              </li>
              {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/crafted/pages/profile/addresses' && 'active')
                }
                to='/crafted/pages/profile/addresses'
              >
                Adrese
              </Link>
            </li> */}
              {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/crafted/pages/profile/documents' && 'active')
                }
                to='/crafted/pages/profile/documents'
              >
                {intl.formatMessage({id: 'PROFILE.HEADER_DOCUMENTS'})}
              </Link>
            </li> */}
              {/*  <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/crafted/pages/profile/connections' && 'active')
                }
                to='/crafted/pages/profile/connections'
              >
                Connections
              </Link>
            </li>*/}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export {ProfileHeader}
